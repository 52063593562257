<template>
  <div class="h-full flex">
    <div
      class="
        flex flex-col
        justify-center
        items-center
        flex-grow
        lg:w-1/2
        overflow-y-auto
        px-6
        mb-16
        h-full
      "
    >
      <div class="mb-12">
        <img src="https://cdn.hashku.com/assets/hashku-logotype.png" style="width: 145px" />
      </div>
      <div class="w-full">
        <!-- <div class="text-center mb-6 flex justify-center items-center">
          <img :src="require('@/assets/or.png')" style="height: 17px" />
        </div> -->
        <form @submit.prevent="login" class="flex flex-col items-center">
          <form-input
            v-model="loginForm.email"
            placeholder="Email"
            class="text-lg w-full mb-4"
            style="max-width: 375px"
          />
          <form-input
            v-model="loginForm.password"
            placeholder="Password"
            class="text-lg w-full mb-4"
            style="max-width: 375px"
            type="password"
          />
          <button class="btn btn-lg" type="submit" :disabled="loading">Login</button>
        </form>
      </div>
    </div>
    <!-- <div
      class="hidden lg:flex w-1/2 h-full p-8 justify-center items-center"
      style="background: #bce7f7"
    >
      <div class="w-full" style="max-width: 800px">
        <img :src="require('@/assets/soth/lockup-login.png')" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { reactive, ref, computed } from "vue";
import store from "@/store";
import router from "@/router";
import constants from "@/constants";
import FormInput from "@/components/FormInput.vue";

export default {
  name: "Login",
  components: {
    FormInput,
  },
  props: {
    redirect: String,
    reason: String,
    query: String,
  },
  setup(props) {
    const loading = ref(false);
    const mode = ref("trigger"); // trigger, verify

    const loginForm = reactive({
      email: null,
      password: null,
    });
    const login = () => {
      loading.value = true;

      store
        .dispatch("auth/login", loginForm)
        .then((data) => {
          console.log("logged in");
          if (props.redirect) {
            router.push({
              path: props.redirect,
              query: props.query,
            });
          } else {
            router.push({ name: constants.HOME_ROUTE });
          }
        })
        .catch((e) => {
          store.dispatch("toasts/add", {
            message: "Invalid or expired token",
            variant: "error",
          });
          console.log(e);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      login,
      loginForm,
      loading,
    };
  },
};
</script>
