<template>
  <div class="h-full flex justify-center items-center">
    <div class="logout text-gray-400">Logging out...</div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import store from "@/store";
import router from "@/router";
import constants from "@/constants";

export default {
  name: "Logout",
  props: {
    redirect: String,
  },
  setup(props) {
    const logout = () => {
      console.log("attempt");
      store
        .dispatch("auth/logout")
        .then((data) => {
          console.log("logged out");
          router.push({
            name: constants.LOGIN_ROUTE,
            query: {
              redirect: props.redirect,
            },
          });
        })
        .catch((e) => {
          console.log(e);
          router.push({
            name: constants.LOGIN_ROUTE,
            query: {
              redirect: props.redirect,
            },
          });
        });
    };

    onMounted(() => {
      logout();
    });
    return {
      logout,
    };
  },
};
</script>
